export class Recarga {
  constructor(
    public id:number,
    public name: string,
    public saldo: number,
    public preco: number,
    public qtd: number,
    public foto: string
  ) {

  }
}
